import { Component, Input, OnInit } from '@angular/core';

import {CategoriaServicio, CategoriaServicioClient, Servicio, ServicioClient} from '../../../services/ApiClient'
import { Images} from '../../../services/StorageClient'
@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit {
  CategoriaServicios: CategoriaServicio[];
  images: Images;
  filterCategoriaServicios: CategoriaServicio[];
  private _searchText : string;  
  Servicios: Servicio[];

  get searchText(): string{
    return this._searchText;
    
  }
 
  set searchText(value: string)
  {
    this._searchText = value;
    console.log(value);
    this.filterCategoriaServicios = this.filteredCategories(value);
  }


 filteredCategories(searchString:string)
 {
   return this.CategoriaServicios.filter(c=>
    c.titulo.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
 }

  constructor(private serviciosClient: ServicioClient, private categoriaClient: CategoriaServicioClient) {
    this.images = new Images();
    categoriaClient.getAll().subscribe(
      result => {
          this.CategoriaServicios = result.sort((a, b) => (a.id > b.id) ? 1 : -1);         
          this.filterCategoriaServicios = this.CategoriaServicios;
          
      },
      error => console.error(error)
      
  );
  
}

  ngOnInit() {    
    
  }

}
