<div class="politics">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two ActiveOption="politicas"></app-header-two>
        <app-breadcrumb-politics></app-breadcrumb-politics>        
        <section class="section faq-area ptb_100">            
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                        <!-- Single Faq -->
                        <a target="_blank" href="https://stsummaweb.blob.core.windows.net/documents/Pol%C3%ADtica-protecci%C3%B3n-de-datos.pdf">
                            <div class="single-faq bg-white px-4 py-3">
                            <div class="media">
                                <img class="rounded-circle avatar-sm" src="assets/img/icon_5.png" alt="">
                                <div class="media-body align-self-center ml-3">
                                    <h5>Política de Datos</h5>
                                </div>
                            </div>                    
                        </div>
                    </a>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <!-- Single Faq -->
                        <a target="_blank" href="https://stsummaweb.blob.core.windows.net/documents/Manual%20de%20contratistas%20Summa.pdf">
                        <div class="single-faq bg-white px-4 py-3">
                            <div class="media">
                                <img class="rounded-circle avatar-sm" src="assets/img/icon_6.png" alt="">
                                <div class="media-body align-self-center ml-3">
                                    <h5>Manual de Contratistas</h5>
                                </div>
                            </div>                    
                        </div>
                        </a>
                    </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <!-- Single Faq -->
                        <a target="_blank" href="https://stsummaweb.blob.core.windows.net/documents/Poli%CC%81tica%20Seguridad%20y%20Salud%20en%20el%20Trabajo.pdf">
                        <div class="single-faq bg-white px-4 py-3">
                            <div class="media">
                                <img class="rounded-circle avatar-sm" src="assets/img/icon_6.png" alt="">
                                <div class="media-body align-self-center ml-3">
                                    <h5>Política Seguridad y Salud en el Trabajo</h5>
                                </div>
                            </div>                    
                        </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
</div>
<app-footer></app-footer>
</div>
