<section id="benifits" class="section benifits-area ptb_100 background-resources">
    
    <div class="container resources-container">
        <span class="badge badge-tags badge-recursos badge-secondary">Recursos</span>
        <div class="grid-container">    
                              
            <div class="Emma">
                <a href="https://emma.service-now.com/sp" target="blank">  
                <div class="benifits-item text-center p-3">                    
                    <div class="feature-icon feature-content">
                        <img class="emma-icon-img" src="assets/img/Summa Site-Logoemma.png" alt="">
                    </div>
                </div>
                </a>
            </div>
            
            <div class="catalogo">               
<a href="/catalog">
                <div class="feature-icon feature-content">
                    <div class="benifits-item text-center p-3 feature-content">
                        <!-- Benifits Text -->
                        <div class="benifits- ">
                            <img class="catalogo-servicios" src="assets/img/Summa Site-PalabraCatálogo_06.png" alt="">
                        </div>
                    </div>
                </div>
</a>
            </div>
            <div class="metricas">
                <a href="/metricas">
                <div class="feature-icon feature-content">
                    <div class="benifits-item text-center p-3 feature-content">
                        <!-- Benifits Text -->
                        <div class="benifits- ">
                            <h3 class="mb-2 metricas-sp1">Métricas e</h3>
                            <h3 class="mb-2 metricas-sp2">indicadores</h3>
                            <h3 class="mb-2 metricas-sp3">Summa</h3>
                        </div>
                    </div>
                </div>
                </a>
            </div>
        </div>


    </div>
</section>