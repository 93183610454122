import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoriaServicio, CategoriaServicioClient, Servicio, ServicioClient } from 'src/app/services/ApiClient';


@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {
  CategoriaServicios: any;  
  servicio: Servicio;
  private _searchText : string; 
  filterCategoriaServicios: CategoriaServicio[];
  ServicioId: any;
  get searchText(): string{
    return this._searchText;
  }
 
  set searchText(value: string)
  {
    this._searchText = value;
    console.log(value);
    this.filterCategoriaServicios = this.filteredCategories(value);
  }

  filteredCategories(searchString:string)
 {
   return this.CategoriaServicios.filter(c=>
    c.titulo.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
 }

 
 constructor(private serviciosClient: ServicioClient, private categoriaClient: CategoriaServicioClient,private route: ActivatedRoute) {

  categoriaClient.getAll().subscribe(
    result => {
      this.CategoriaServicios = result.sort((a, b) => (a.id > b.id) ? 1 : -1);    
      this.filterCategoriaServicios = this.CategoriaServicios;     
      
    },
    error => console.error(error)  
  );

  this.ServicioId = this.route.snapshot.paramMap.get("id");

  console.log(this.ServicioId);

  serviciosClient.get(this.ServicioId).subscribe(
    result => {
      
      
        this.servicio = result;       
    },
    error => console.error(error)
);

  }

ngOnInit(): void {
  
 
}

 

}
