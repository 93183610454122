
   <div class="blog">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two ActiveOption="catalogo"></app-header-two>
        <app-breadcrumb-blog-left-sidebar></app-breadcrumb-blog-left-sidebar>
     
        <section id="blog" class="section blog-area  ptb_100">
            <div class="catalog-area"></div>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <aside class="sidebar">
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Search Widget -->
                                <div class="widget-content search-widget">

                                    <form action="#">
                                        <div class="input-container">
                                            <i class="fa fa-search icon"></i>
                                            <input type="text" class="input-field search-servicio" placeholder="Busca tu servicio"
                                                name="searchText" [(ngModel)]="searchText"  >
                                          </div>                           
                             
                                          
                                    </form>
                                
                                </div>
                            </div>
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Category Widget -->
                                <div class="accordions widget catagory-widget" id="cat-accordion">
                                    <span class="badge badge-tags badge-recursos badge-secondary categoria-title">Servicios</span>
                                    <div class="single-accordion blog-accordion">

                                        
                                        <!-- Category Widget Content -->
                                        <div id="accordion1" class="accordion-content widget-content collapse show" data-parent="#cat-accordion">
                                            <!-- Category Widget Items -->
                                            <ul class="widget-items categorias">
                                                <li *ngFor="let categoria of filterCategoriaServicios"><a href="/catalog-detail/{{categoria.id}}" class="d-flex p-3 categoria"><span>{{categoria.titulo}}</span><span class="ml-auto">({{categoria.servicios.length }})</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>                           
                            
                        </aside>
                    </div>
                    <div class="col-12 col-lg-9">
                        <div class="row">
                            
                                
                                <div class="col-12 col-md-6" *ngFor="let categoria of filterCategoriaServicios">
                                    <!-- Single Blog -->
                                    <div class="single-blog res-margin">
                                        <!-- Blog Thumb -->
                                        <div class="blog-thumb">
                                            <a href="/catalog-detail/{{categoria.id}}"><img src="{{images.imagesUrl}}/{{categoria.urlImagen}}" alt=""></a>
                                        </div>
                                        <!-- Blog Content -->
                                        <div class="blog-content p-4">                                       
                                            <!-- Blog Title -->
                                            <h3 class="blog-title my-3 summa-h3">{{categoria.titulo}}</h3>
                                            <!--<p class="summa-p mb-3">{{categoria.descripcion}}</p>-->
                                            <div class="text-center">
                                            <a href="/catalog-detail/{{categoria.id}}" class=" mt-3 p-1 summa-button-b">Conoce el servicio completo</a>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                              
                          
                        </div>
               
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </div>
</div>