<footer class="section footer-area footer-bg">
    <!-- Footer Top -->
    <div class="footer-top ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-12 col-lg-6">
                    <!-- Footer Items -->
                    <div class="footer-items text-center">
                        <!-- Logo -->
                        <a class="navbar-brand" href="#">
                            <img class="logo footer-logo" src="assets/img/Summa Site-LogoSummaPiePágina.png" alt="">
                        </a>                        
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-lg-6">
                    <!-- Footer Items -->
                    <div class="footer-items text-center">
                        <!-- Logo -->
                        <a class="navbar-brand" href="#">
                            <figure>
                                <img class="logo footer-logo" src="assets/img/FooterQR.png" alt="PQRS">
                                <figcaption class="summa-white">Escanéalo para gestionar tus PQR</figcaption>
                              </figure>
                            
                        </a>
                       
                    </div>
                </div>
               
            </div>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="copyright-area col-6">
                    <!-- Copyright Area -->
                    <div class="d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="text-white-50 copyright-text">
                            <address>
                            Contáctanos<br>
                            Protección datos personales protecciondedatos@summa-sci.com<br>
                            Línea de transparencia 01-8000-126-166<br>
                            Notificaciones legales notifilegal@summa-sci.com<br>
                            Atención a proveedores Shappy@summa-sci.com<br>                            
                            </address>
                        </div>   
                                      
                    </div>
                </div>
                <div class="col-6 copyright-area">
                    <!-- Copyright Area -->
                    <div class="d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->                        
                        <div class="copyright-left text-white-50 copyright-text">
                            PBX +57 (4) 604 63 98<br>
                            &copy;Summa-SCI
                        </div>                        
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</footer>