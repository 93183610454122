<section id="home" class="section welcome-area d-flex align-items-center ">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-6">
                <div class="welcome-intro">
                    <div  class="title-home-background">
                        <h2 class="title-home-text">Sabías que:</h2>
                    </div>
                    <h3 class="h2-summa">SOMOS UNA </h3>
                    <h3 class="h2-summa">EMPRESA GRUPO ARGOS</h3>
                    <p class="my-3 h3-summa">Servir nos mueve</p>
                    <p class="my-3 p-summa">Creamos soluciones a la medida, prestamos servicios transversales y especializados que generan valor, 
                        crecimiento a la sociedad y sostenibilidad a las compañías a las que prestamos nuestros servicios. 
                        <strong>Brindando una experiencia de servicio diferencial.</strong> </p>
                    <div class="button-group">
                       <!-- <button href="#" class="btn-primary-summa"><span>CONÓCENOS</span></button>-->
                       
                    </div>
                </div>
            </div>

            
            <div class="col-12 col-md-5 col-lg-6">
                
                <div class="video-icon d-none d-lg-block">
                    <a class="play-btn" data-fancybox href="https://youtu.be/3rjvhffMf-A">
                        <i class="icofont-ui-play"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
