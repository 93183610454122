<div class="italy">
    <app-scrollup></app-scrollup>
    <div class="all-area">        
        <section id="home" class="section welcome-video d-flex align-items-center h-100vh pt-0">            
            <div class="video-container">
                <div class="row align-items-center justify-content-center video-container">
                    <iframe id="fancybox-frame1633990607235" name="fancybox-frame1633990607235" class="fancybox-iframe" allowfullscreen="allowfullscreen" allow="autoplay; fullscreen" src="https://www.youtube-nocookie.com/embed/jC0Z5tHpmT0?autoplay=1&amp;autohide=1&amp;fs=1&amp;rel=0&amp;hd=1&amp;wmode=transparent&amp;enablejsapi=1&amp;html5=1&amp;controls=0&amp;showinfo=0" scrolling="no"></iframe>
                </div>
            </div>           
        </section>      
        <app-footer></app-footer>
    </div>
</div>