<header class="section header-area">
    <div id="appo-header" class="main-header-area">
        <div class="container-fluid navbar-summa">
            <nav class="navbar navbar-expand-md navbar-light">
                <!-- Logo -->
                <a class="navbar-brand  ml-5" href="/">
                    <img class="logo" src="assets/img/logo.png" alt="Summa-sci">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Appo Menu -->
                <div class="collapse navbar-collapse" id="appo-menu">
                    <!-- Header Items -->
                    <ul class="navbar-nav header-items ml-auto">
                        <li class="nav-item active dropdown">
                            <a class="nav-link active" href="/">
                                Home
                            </a>
                           
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" target="blank" href="https://emma.service-now.com/sp">Emma</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/metricas">Métricas</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/catalog">Catálogo</a>
                        </li>
                       
                        <li class="nav-item">
                            <a class="nav-link" href="/politicas">Políticas</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>