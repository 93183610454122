import { Component, OnInit } from '@angular/core';
import { CategoriaServicio, CategoriaServicioClient, ServicioClient } from 'src/app/services/ApiClient';
import { ActivatedRoute } from "@angular/router";
import { Images} from '../../../services/StorageClient'
@Component({
  selector: 'app-catalog-detail',
  templateUrl: './catalog-detail.component.html',
  styleUrls: ['./catalog-detail.component.css']
})
export class CatalogDetailComponent implements OnInit {

  
  CategoriaServicio: CategoriaServicio;
  images: Images;
  RouterModule: any;
  CategoriaId: any;
  CategoriaServicios: CategoriaServicio[];
  filterCategoriaServicios: CategoriaServicio[];
  private _searchText : string; 

  get searchText(): string{
    return this._searchText;
  }
 
  set searchText(value: string)
  {
    this._searchText = value;
    console.log(value);
    this.filterCategoriaServicios = this.filteredCategories(value);
  }

  
 filteredCategories(searchString:string)
 {
   return this.CategoriaServicios.filter(c=>
    c.titulo.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
 }

  constructor(private serviciosClient: ServicioClient, private categoriaClient: CategoriaServicioClient,private route: ActivatedRoute) {
    this.images = new Images();
    categoriaClient.getAll().subscribe(
      result => {
        this.CategoriaServicios = result.sort((a, b) => (a.titulo > b.titulo) ? 1 : -1);    
        this.filterCategoriaServicios = this.CategoriaServicios;     
      },
      error => console.error(error)
  );
    this.CategoriaId = this.route.snapshot.paramMap.get("id");
    categoriaClient.get(this.CategoriaId).subscribe(
      result => {
          this.CategoriaServicio = result;
         
      },
      error => console.error(error)
  );
    }

  ngOnInit(): void {
    
   
  }

}
