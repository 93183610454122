<div class="blog">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two></app-header-two>
        <app-breadcrumb-blog-details-left-sidebar></app-breadcrumb-blog-details-left-sidebar>
        <section id="blog" class="section blog-area ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <aside class="sidebar">
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Search Widget -->
                                <div class="widget-content search-widget">

                                    <form action="#">
                                        <div class="input-container">
                                            <i class="fa fa-search icon"></i>
                                            <input type="text" class="input-field search-servicio" placeholder="Busca tu servicio"
                                            name="searchText" [(ngModel)]="searchText"  >
                                          </div>                         
                             
                                        
                                    </form>
                                
                                </div>
                            </div>
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Category Widget -->
                                <div class="accordions widget catagory-widget" id="cat-accordion">
                                    <span class="badge badge-tags badge-recursos badge-secondary categoria-title">Servicios</span>
                                    <div class="single-accordion blog-accordion">
                                        
                                        
                                        <!-- Category Widget Content -->
                                        <div id="accordion1" class="accordion-content widget-content collapse show" data-parent="#cat-accordion">
                                            <!-- Category Widget Items -->
                                            <ul class="widget-items categorias">
                                                <li *ngFor="let categoria of filterCategoriaServicios"><a href="/catalog-detail/{{categoria.id}}" class="d-flex p-3 categoria"><span>{{categoria.titulo}}</span><span class="ml-auto">({{categoria.servicios.length }})</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            
                        </aside>
                    </div>
                    <div class="col-12 col-lg-9">
                        <!-- Single Blog Details -->
                        <article class="single-blog-details">
                            <!-- Blog Thumb -->
                            <div class="blog-thumb">
                                <a href="#"><img src="{{images.imagesUrl}}/{{CategoriaServicio.urlImagen}}" alt=""></a>
                            </div>
                            <!-- Blog Content -->
                            <div class="blog-content appo-blog mt-2 servicio-content">
                                <!-- Meta Info -->
                                <h3 class="blog-title pt-2 servicio-title">{{CategoriaServicio.titulo}}</h3>
                                <div class="meta-info d-flex flex-wrap align-items-center py-2">                                    
                                    <ul>                                        
                                        <li class="d-inline-block pt-2 servicio-subtitle">{{CategoriaServicio.servicios.length }} Servicios adjuntos</li>
                                    </ul>
                                    
                                </div>
                                <!-- Blog Details -->
                                <div class="blog-details mt-3">
                                    
                                    <p class="d-none d-sm-block summa-p">{{CategoriaServicio.descripcion}}</p>
                                    
                                </div>
                                <section id="benifits" class="section benifits-area ptb_100">
                                    <div class="container">
                                        
                                        <div class="row">
                                            <!-- Benifits Item -->
                                        
                                           <div class="col-12 col-md-6 col-lg-4"  *ngFor="let servicio of CategoriaServicio.servicios">
                                                <!-- Single Faq -->
                                                <a href="catalog/service/{{servicio.id}}">
                                                <div class="single-faq bg-white px-4 py-3">
                                                    <div class="media">
                                                        
                                                   
                                                        <div class="media-body align-self-center  servicio-box-text">
                                                            <h5>{{servicio.titulo}}</h5>
                                                        </div>
                                                    </div>
                                                   <!-- <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nisi, qui ipsa magni itaque. Corporis eaque sit magnam maxime quam.</p>-->
                                                </div>
                                                </a>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </section>
                                
                            </div>
                            
                           
                        </article>
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </div>
</div>