<div class="blog">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two ActiveOption="catalogo"></app-header-two>
        <app-breadcrumb-blog-details-left-sidebar></app-breadcrumb-blog-details-left-sidebar>
        <section id="blog" class="section blog-area ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <aside class="sidebar">
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Search Widget -->
                                <div class="widget-content search-widget">

                                    <form action="#">
                                        <div class="input-container">
                                            <i class="fa fa-search icon"></i>
                                            <input type="text" class="input-field search-servicio" placeholder="Busca tu servicio"
                                            name="searchText" [(ngModel)]="searchText"  >
                                          </div>                         
                             
                                        
                                    </form>
                                
                                </div>
                            </div>
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Category Widget -->
                                <div class="accordions widget catagory-widget" id="cat-accordion">
                                    <span class="badge badge-tags badge-recursos badge-secondary categoria-title">Servicios</span>
                                    <div class="single-accordion blog-accordion">
                                        
                                        
                                        <!-- Category Widget Content -->
                                        <div id="accordion1" class="accordion-content widget-content collapse show" data-parent="#cat-accordion">
                                            <!-- Category Widget Items -->
                                            <ul class="widget-items categorias">
                                                <li *ngFor="let categoria of filterCategoriaServicios"><a href="/catalog-detail/{{categoria.id}}" class="d-flex p-3 categoria"><span>{{categoria.titulo}}</span><span class="ml-auto">({{categoria.servicios.length }})</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            
                        </aside>
                    </div>
              
                    <div class="col-12 col-lg-9">
                        <!-- Single Blog Details -->
                        <!-- Blog Thumb -->
                        <div class="float-right"><a href="/catalog-detail/{{servicio.categoriaId}}" class="btn-primary-summa">REGRESAR A SERVICIO</a></div>    
                        <article class="single-blog-details">
                            
                            <!-- Blog Content -->
                            <div class="blog-content appo-blog mt-2 servicio-content">
                                <!-- Meta Info -->
                                <h3 class="blog-title pt-2 servicio-title">{{servicio.titulo}}</h3>
                             
                                <!-- Blog Details -->
                                <div class="blog-details mt-3">                                   
                                    <div [innerHTML]="servicio.descripcion"></div>
                                </div>
                      
                                
                            </div>
                            
                           
                        </article>
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </div>
</div>