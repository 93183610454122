<section class="section breadcrumb-area  overlay-dark d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- Breamcrumb Content -->
                <div class="breadcrumb-content d-flex flex-column align-items-center text-center">                    
                    <img class="title-catalogo" src="assets/img/Subpágina-Políticas-Texto políticas.png" alt="">                    
                </div>
            </div>
        </div>
    </div>
</section>
