<section id="screenshots" class="section screenshots-area ptb_100 background">
    <div class="container section-heading text-center">        
        <h1 class="summa-t2">Nuestros</h1>
        <h1 class="text-capitalize summa-t1">Webinars </h1>
        <div class="float-right"><button class="btn-primary-summa-white btn-envivo">¡EN VIVO AHORA!</button></div>    
    </div>
    
    <div class="container">        
        <div class="row">
            <div class="col-12">
                <!-- App Screenshot Slider Area -->
                <div class="app-screenshots">
                   
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screenshot_1.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="p-2 pt-3 screenshots-overlay screenshots-overlay-1">
                            <h3 class="screenshots-overlay-1-title mb-3">Actualización en PowerBI</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                            </p>                            
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screenshot_2.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="p-2 pt-3 screenshots-overlay screenshots-overlay-2">
                            <h3 class="screenshots-overlay-2-title mb-3">Actualización en PowerBI</h3>
                            <p class="text-white">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                            </p>                            
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screenshot_3.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="p-2 pt-3 screenshots-overlay screenshots-overlay-1">
                            <h3 class="screenshots-overlay-1-title mb-3">Actualización en PowerBI</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                            </p>                            
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screenshot_4.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="p-2 pt-3 screenshots-overlay screenshots-overlay-2">
                            <h3 class="screenshots-overlay-2-title mb-3">Actualización en PowerBI</h3>
                            <p class="text-white">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                            </p>                            
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screenshot_5.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="p-2 pt-3 screenshots-overlay screenshots-overlay-1">
                            <h3 class="screenshots-overlay-1-title mb-3">Actualización en PowerBI</h3>
                            <p >
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                            </p>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>