import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';

import { environment } from '../../environments/environment';

export const IMAGES_BASE_URL = new InjectionToken<string>('IMAGES_BASE_URL');

@Injectable()
export class Images {
    
    public imagesUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor( @Optional() @Inject(IMAGES_BASE_URL) imagesBaseUrl?: string) {
        
        this.imagesUrl = imagesBaseUrl ? imagesBaseUrl : environment.imagesUrl;
    }
}